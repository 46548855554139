<template>
  <div class="homePage">
    <!-- 上部分 -->
    <div class="corporateInformation">
      <div class="left">
        <p class="name">
          <img src="@/assets/image/homePage_4.png" alt="homePage_4"> 你好，{{ userInfo.userName }}
        </p>
        <div class="internalInformation">
          <div class="item">
            <p>组织总人数</p>
            <span>206</span>
          </div>
          <div class="item">
            <p>超级管理员</p>
            <span>1</span>
          </div>
          <div class="item">
            <p>创建角色数</p>
            <span>23</span>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="companyName">
          <!-- 公司名简称 -->
          <p class="abbreviationCompany">
            <span>{{ userInfo.abbreviation }}</span>
          </p>
          <div class="companyCertification">
            <p class="name">
              {{ userInfo.orgName }}<span v-if="userInfo.authState==='0'" @click="enterpriseCertification">立即认证</span>
              <span v-else>已认证</span>
            </p>
            <p class="tip">
              完成认证后可立享合同签署、电子签章等权益
            </p>
          </div>
        </div>
        <div class="addBox">
          <router-link :to="{path:'/institutionalFramework/user'}">
            <img src="@/assets/image/homePage_1.png" alt="icon">
            添加成员
          </router-link>
          <router-link :to="{path:'/institutionalFramework/role'}">
            <img src="@/assets/image/homePage_2.png" alt="icon">
            新增角色
          </router-link>
        </div>
      </div>
    </div>
    <!-- 下部分图片 -->
    <div class="bottom">
      <img src="@/assets/image/homePage_5.jpg" alt="">
    </div>
  </div>
</template>

<script>
import { cmpBaseinfoAuth } from '@/api/enterpriseInformation'
export default {
  data() {
    return {
      userInfo: {}
    }
  },
  mounted() {
    this.userInfo = { ...JSON.parse(localStorage.getItem('userInfoWms')) }
    if (this.userInfo.orgName) { this.userInfo.abbreviation = this.userInfo.orgName.slice(0, 4) }
  },
  methods: {
    // 企业认证
    enterpriseCertification() {
      cmpBaseinfoAuth({ cmpCode: JSON.parse(localStorage.getItem('userInfoWms')).cmpId }, res => {
        window.open(res.data)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.homePage {
  .bottom{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #fff;
  }
  .corporateInformation {
    display: flex;
    margin-bottom: 17px;
    .left {
      flex: 1;
      height: 1px;
      height: 228px;
      background-color: #fff;
      .internalInformation{
        padding-top: 6px;
        display: flex;
        .item{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex: 1;
          color: $mainFontColor;
          font-size: 14px;
          border-right: 1px solid $mainBorderColor;
          span{
            margin-top: 8px;
            font-size: 24px;
            font-weight: 800;
          }
        }
        .item:last-of-type{
          border-right: 0;
        }
      }
      .name{
        display: flex;
        align-items: center;
        padding: 35px 0 22px 36px;
        color: $mainFontColor;
        font-size: 18px;
        img{
          height: 90px;
          width: 90px;
          margin-right: 18px;
        }
      }
    }

    .right {
      flex: 1;
      background-color: #fff;
      height: 228px;
      margin-left: 17px;
      .addBox{
        display: flex;
        margin: 26px 0 0 34px;
        a{
          display: flex;
          height: 71px;
          width: 258px;
          align-items: center;
          margin-right: 48px;
          background-color: #F9F9F9;
          font-size: 14px;
          color: $mainFontColor;
          img{
            height: 29px;
            width: 32px;
            margin:0 32px;
          }
        }
      }
      .companyName{
        display: flex;
        margin: 25px 0 0 34px;
        .companyCertification{
          height: 90px;
          display: flex;
          align-items: left;
          justify-content: space-around;
          flex-direction: column;
          .tip{
            font-size: 14px;
            color: $mainFontColor;
          }
          .name{
            font-size: 18px;
            font-weight: 700;
            color: $mainFontColor;
            span{
              height: 22px;
              width: 62px;
              color: #fff;
              font-size: 12px;
              display: inline-block;
              line-height: 22px;
              text-align: center;
              margin-left: 8px;
              border-radius: 2px;
              background-color: $mainBlue;
            }
          }
        }
        .abbreviationCompany{
          margin-right: 17px;
          height: 90px;
          width: 90px;
          background-color: $mainBlue;
          color: #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          span{
            font-size: 18px;
            font-weight: 900;
            height: 48px;
            width: 48px;
            text-align: center;
            display: inline-block;
            text-align-last:justify;
	text-align:justify;
	text-justify:distribute-all-lines;
          }
        }
      }
    }
  }
}
</style>
